@import url("../../styles/fonts.css");

::-webkit-scrollbar 
{
    position: absolute;
    width: 1vw;
    border-radius: 0.3vw;
    margin: 1vh;
}

#mainInfoButtonContainer 
{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4vw;
  height: 6vh;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 0.4vh;
  margin-right: 0.1vw;
  z-index: 4;
  pointer-events: all;
  cursor: pointer;
}

#mainInfoButtonContainer.disabled
{
  pointer-events: none;
}

#mainInfoButton 
{
  width: 120%;
  height: 120%;
  color: white;
}

#mainInfoBG
{
  position: absolute;
  z-index: 7;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

#mainInfoContainer 
{
  z-index: 8;
  width: 90%;
  height: 90%;
  background-color: white;
  overflow-y:auto;
  pointer-events: all;
  box-sizing: border-box;
  padding: 2vw;
  position: relative;
  border-radius: 2vw;
}

#mainInfoContainer > h1
{
  font-size: 2vw;
  color: black;
  text-align: center;
  margin-bottom: 5vh;
}

#mainInfoContainer > .infoBox > h2 
{
  color: black;
}

#mainInfoContainer > p
{
  font-size: 1.5vw;
  text-align: justify;
  color: black;
  font-family: 'Roboto', sans-serif;
}

#exitMainInfoButton 
{
  pointer-events: all;
  cursor: pointer;
  right: 0;
  top: 0;
  position: absolute;
  background-color: transparent;
  border-color: transparent;
  width: 50px;
  height: 50px;
}

#exitMainInfoButton > svg
{
  width: 100%;
  height: auto;
}

.infoBox > p {
  color: black;
  font-size: 17px;
  text-align: justify;
}
