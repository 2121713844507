@import url("../../../../styles/fonts.css");

#marcadoresBigBox {
    width: 100%;
    height: 96%;
    margin-top: 2%;
    margin-bottom: 2%;
    grid-column: 2;
    display: grid;
    grid-template-rows: 20% 80%;
    
    overflow-y:auto;
    pointer-events: all;
    
}

a {
    pointer-events: all;
}

#ranking-cards-container {
    width: 100%;
    height: 96%;
    justify-content: center;
    flex-direction: row;
}   

#filtroMarcador {
    text-align: center;
    align-content: center;
    width: 100%;
    height: 80%;
    display: flex;
    font-family: vanilla-regular;
    color: white;
    
    flex-wrap: wrap;
    flex-direction: column;

    display: flex;
    justify-content: center;
    align-items: center;
}



#filtroMarcador > svg {
    height: 6.3vh;
    margin-right: 2vw;
    width: auto;
}

.inputElemRanking {
    max-height: 80%;
    margin-right: 1em;
    font-family: vanilla-regular;
    border-radius: 1vw;
    border: 0.2vw solid gray;
    color: #424242;
    text-align: center;
    width: auto;
    pointer-events: all;
    width: 20vw;
    height: 6vh;
    outline: none;
}

option 
{
    font-family: vanilla-regular;
}

@media screen and (max-width: 1100px) {
    .inputElemRanking {
        width: 25vw;
        height: 5vh;
        font-size: 1.2vw;
    }

    #filtroMarcador > svg {
        height: 5vh;
        margin-right: 1.5vw;
    }

    .filterLabel {
        font-size: 1.5vw;
    }
}

@media screen and (max-width: 700px) {
    .inputElemRanking {
        width: 45vw;
        height: 4vh;
        font-size: 2.5vw;
    }

    #filtroMarcador > svg {
        height: 4vh;
        margin-right: 1.5vw;
    }
    
    .filterLabel {
        font-size: 2.7vw;
    }
}



@import url("./rankingCard.css");

@import url("./types.css");