#ruletaSpecialButtonsContainer
{
    margin-right: 1vw;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
}

#tirarButton 
{
    background-color: #006400;
    border-radius: 0.7vw;
    border: 0.3vw solid #89FF8E;
    color: white;
    width: 9vw;
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    pointer-events: all;
    min-width: 40px;
    min-height: 30px;
    padding: 0 1vw;
}

#tirarButton p 
{
    font-size: calc(3px + 1vw);
    margin: 0;
}

#tirarButton img 
{
    width: 1vw;
    height: 1vw;
    margin-left: 0.1vw;
    min-width: 10px;
    min-height: 10px;
}

#tirarButton div 
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.pokeBall {
    width: 20vw;
    position: relative;
    transform: translateY(50%);
    bottom: 60%;
}

#tirarButton.disabled
{
    pointer-events: none;
    opacity: 0.5;
}

#tirarButton.notEnoughMoney
{
    background-color: red;
    border: 0.3vw solid #ff7373;
    pointer-events: none;
}

#changeTierButtons
{
    display: grid;
    grid-template-rows: 100%;
    width: 20vw;
    justify-content: center;
    align-items: center;
}

#changeTierButtons > p 
{
    grid-row: 1;
    grid-column: 2;
    margin: 0;
    font-size: calc(1px + 1vw);
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 0.5vw;
    color: white;
}


#changeTierButtons > .changeTierButton
{
    width: 5vw;
    height: 4vw;
    background-color: #006400;
    border: 0.3vw solid #89FF8E;
    border-radius: 0.7vw;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    cursor: pointer;
}

#changeTierButtons > .changeTierButton > svg 
{
    width: 100%;
    height: 100%;
    color: white;
}

#changeTierButtons > div.disabled
{
    opacity: 0.5;
    pointer-events: none;
}

#nextTierButton
{
    grid-row: 1;
    grid-column: 3;
}

#prevTierButton
{
    grid-row: 1;
    grid-column: 1;
}

#prevTierButton > svg 
{
    transform: scaleX(-1);
}

/* Accciones cuando la anchura es menor a la altura */
@media (max-aspect-ratio: 1/1)
{
    #ruletaSpecialButtonsContainer
    {
        top: -75%;
        right: initial;
        margin: 0;
        width: 100%;
    }

    #changeTierButtons
    {
        margin-right: 3vw;
        width: 25vw;
    }

    #changeTierButtons > p
    {
        font-size: 10px;
    }

    #prevTierButton,
    #nextTierButton,
    #tirarButton 
    {
        min-width: 40px;
        min-height: 40px;
    }

    #tirarButton p 
    {
        font-size: 10px;
    }
    
    #tirarButton
    {
        padding: 0 1vw;
        margin-left: 3vw;
    }
    

}