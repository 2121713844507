@import url('../../../styles/fonts.css');

#coins 
{
    display: grid;
    grid-template-columns: 40% 60%; 
    grid-row-gap: 0.8em;
}

#coins > div
{
    align-items: center;
    justify-content: center;
    display: flex;
}

#coins img 
{
    top: 50%;
    left: 10%;
    transform: translate(0, -50%);
    position: relative;
    width: 50%;
    height: auto;
    pointer-events: none;
}

#coins p
{
    margin: 0 0.4em 0 0;
    justify-self: right;
    align-self: center;
    color: white;
    font-family: komikazoom;
}

#cifras-1
{
    font-size: calc(8px + 3vw);
}

#cifras-2
{
    font-size: calc(6px + 3vw);
}

#cifras-3
{
    font-size: calc(4.5px + 3vw);
}

#cifras-4
{
    font-size: calc(4px + 3vw);
}

#cifras-5 
{
    font-size: calc(2px + 3vw);
}

#cifras-6
{
    font-size: calc(1.5px + 3vw);
}

#cifras-7
{
    font-size: calc(1px + 3vw);
}

#cifras-8
{
    font-size: calc(1px + 2.5vw);
}

#cifras-9
{
    font-size: calc(1px + 2.1vw);
}