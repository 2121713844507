.formularioContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 2;
}

.formulario {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 7vh;
}

.intercambioTextoInicio {
  width: 50vw;
  height: 2vh;
  color: white;
  text-align: center;
  font-size: 2.5dvh;
  margin: 0;
}

.intercambioInputCodigo {
  width: 75%;
  height: 50%;
  border-radius: 15px;
  font-size: 3vh;
  text-align: center;
  pointer-events: all;
}

.intercambioBoton {
  padding: 10px 20px;
  border: 0.3vw solid #89ff8e;
  background-color: #006400;
  color: white;
  border-radius: 30px;
  cursor: pointer;
  pointer-events: all;
}

.intercambioBoton:hover {
  padding: 10px 20px;
  border: 0.3vw solid #333;
  background-color: darkgreen;
  color: white;
  border-radius: 30px;
  cursor: pointer;
}

.intercambioBotonConCodigo.enabled {
  padding: 10px 20px;
  border: 0.3vw solid #89ff8e;
  background-color: #006400;
  color: white;
  border-radius: 30px;
  cursor: pointer;
  margin: 5px;
  font-size: 1.5dvh;
  text-decoration: none;
}

.intercambioBotonConCodigo.disabled {
  padding: 10px 20px;
  border: 0.3vw solid #89ff8e;
  background-color: #006400;
  color: white;
  border-radius: 30px;
  pointer-events: none;
  margin: 5px;
  font-size: 1.5dvh;
  opacity: 0.5;
}

.intercambioBotonSinCodigo.enabled {
  padding: 10px 20px;
  border: 0.3vw solid #ba9712;
  background-color: #fecb02;
  border-radius: 30px;
  cursor: pointer;
  margin: 5px;
  color: #1a437b;
  font-size: 1.5dvh;
  text-decoration: none;
}

.intercambioBotonSinCodigo.disabled {
  padding: 10px 20px;
  border: 0.3vw solid #ba9712;
  background-color: #fecb02;
  pointer-events: none;
  border-radius: 30px;
  margin: 5px;
  color: #1a437b;
  font-size: 1.5dvh;
  opacity: 0.5;
}

.intercambioBotonSinCodigo > h2 {
  color: #1a437b;
}

.mensajeNoIntercambioPokemonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  grid-column: 2;
}

.mensajeNoIntercambioPokemon {
  font-size: large;
}

.botonesIntercambio {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 2;
  flex-wrap: wrap;
}

.backButtonIntercambio {
  position: absolute;
  z-index: 5;
  top: 0;
  margin-top: 3vh;
  left: 0;
  width: 5%;
  height: 5%;
}

.backButtonIntercambio > a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backArrowIntercambio > svg {
  text-align: center;
  transform: scaleX(-1);
  color: white;
  font-size: 4vw;
  width: 100%;
  height: 100%;
}

#codeAndButtonContainer {
  height: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

#buttonCopyClipboard {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  pointer-events: all;
}

#botonProgresoIniciarIntercambio {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3lvw;
}

#conexionIntercambioContainer {
  grid-column: 2;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  text-align: center;
  height: 100%;
}

#intercambioHostContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  grid-column: 1;
}

#intercambioGuestContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  grid-column: 2;
}

#pokemonIntercambioInfoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 5vw;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

#conexionIntercambioContainer h2 {
  display: flex;
  color: white;
  font-size: calc(2.5vw);
  margin-top: 0;
  margin-bottom: 1.5vh;
}

#arrowAnimHost {
  width: 5vw;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
}

#arrowAnim {
  width: 5vw;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.arrow {
  width: 1vw;
  height: 1vw;
  border: 0.5vw solid;
  border-color: white transparent transparent white;
  transform: rotate(-45deg);
}

.arrowHost {
  width: 1vw;
  height: 1vw;
  border: 0.5vw solid;
  border-color: white transparent transparent white;
  transform: rotate(135deg);
}

.arrowSliding {
  position: absolute;
  -webkit-animation: slide 12s linear infinite;
  animation: slide 12s linear infinite;
}

.arrowSlidingHost {
  position: absolute;
  -webkit-animation: slideHost 12s linear infinite;
  animation: slideHost 12s linear infinite;
}

.delay1 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.delay2 {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}
.delay3 {
  -webkit-animation-delay: 9s;
  animation-delay: 9s;
}

@-webkit-keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(5vw);
  }
  20% {
    opacity: 1;
    transform: translateX(3vw);
  }
  80% {
    opacity: 1;
    transform: translateX(-3vw);
  }
  100% {
    opacity: 0;
    transform: translateX(-5vw);
  }
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(5vw);
  }
  20% {
    opacity: 1;
    transform: translateX(3vw);
  }
  80% {
    opacity: 1;
    transform: translateX(-3vw);
  }
  100% {
    opacity: 0;
    transform: translateX(-5vw);
  }
}

@-webkit-keyframes slideHost {
  0% {
    opacity: 0;
    transform: translateX(-5vw);
  }
  20% {
    opacity: 1;
    transform: translateX(-3vw);
  }
  80% {
    opacity: 1;
    transform: translateX(3vw);
  }
  100% {
    opacity: 0;
    transform: translateX(5vw);
  }
}
@keyframes slideHost {
  0% {
    opacity: 0;
    transform: translateX(-5vw);
  }
  20% {
    opacity: 1;
    transform: translateX(-3vw);
  }
  80% {
    opacity: 1;
    transform: translateX(3vw);
  }
  100% {
    opacity: 0;
    transform: translateX(5vw);
  }
}

#botonCancelarIntercambio {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border: 0.3vw solid #9f4949;
  background-color: #8a0000;
  color: white;
  border-radius: 15px;
  cursor: pointer;
  pointer-events: all;
}

#botonAceptarIntercambio {
  width: 15vw;
  height: 7vh;
  padding: 5px 10px;
  border: 0.3vw solid #89ff8e;
  background-color: #006400;
  color: white;
  border-radius: 30px;
  cursor: pointer;
  pointer-events: all;
  font-size: calc(1.5vw);
  display: flex;
  align-items: center;
  justify-content: center;
}

#exchangePokemonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: all;
  cursor: pointer;
}

.MuiBox-root #almacenBigBox > #pokemon-cards-container > a {
  pointer-events: all;
}

.MuiBox-root #pokemon-cards-container > div {
  margin-bottom: 2vw;
  filter: drop-shadow(0 0 2px rgb(0, 0, 0));
  transition: transform 0.3s ease, filter 0.3s ease; /* Agregamos una transición suave a transform y filter */
}
.MuiBox-root #pokemon-cards-container > div:hover {
  transform: scale(
    1.05
  ); /* Aumentamos el tamaño del elemento en un 5% al pasar el ratón sobre él */
  filter: drop-shadow(
    1px 1px 1px rgb(0, 0, 0)
  ); /* Aplicamos una sombra más pronunciada */
}

.cerrarModalContainer button {
  font-family: vanilla-regular;
  color: white;
  background-color: #fb6c6c;
  border: 0.3vw solid #9f4949;
  border-radius: 0.8vw;
  height: 100%;
  width: 100%;
  margin-top: 0.7vh;
  pointer-events: all;
  font-size: calc(2px + 0.8vw);
  min-width: 0;
  cursor: pointer;
}

.cerrarModalContainer button:hover {
  background-color: #7d3939;
}

.cerrarModalContainer {
  position: absolute;
  top: 3vh;
  z-index: 5;
  left: 2.3vw;
  display: flex;
  flex-direction: column;
  min-width: 40px;
}

.selectIntercambioConfirmDisabled {
  pointer-events: none;
}

#modalSeleccionIntercambio {
  pointer-events: all;
}

@media screen and (max-width: 940px) {
  .intercambioTextoInicio {
    font-size: medium;
  }
}

@media screen and (max-width: 720px) {
  .arrow {
    width: 3vw;
    height: 3vw;
  }
  .arrowHost {
    width: 3vw;
    height: 3vw;
  }
  #conexionIntercambioContainer h2 {
    font-size: large;
  }
  #botonAceptarIntercambio {
    height: auto;
    width: auto;
    font-size: calc(3vw);
  }
  .intercambioBotonSinCodigo.enabled {
    width: 50%;
    font-size: smaller;
  }
  .intercambioBotonConCodigo.enabled {
    width: 50%;
    font-size: small;
  }
  .intercambioBotonSinCodigo.disabled {
    width: 50%;
    font-size: smaller;
  }
  .intercambioBotonConCodigo.disabled {
    width: 50%;
    font-size: small;
  }
  .intercambioTextoInicio {
    font-size: small;
  }
  .mensajeNoIntercambioPokemon {
    font-size: small;
  }
  #intercambioCodeDisplayed {
    font-size: x-large;
  }
  .backButtonIntercambio {
    width: 10vw;
  }
  #buttonCopyClipboard > svg {
    font-size: x-large;
  }
  #codeAndButtonContainer {
    margin-top: 10%;
  }
  .intercambioBoton {
    width: 50%;
    height: 50%;
  }
  #textoBotonIntercambio {
    font-size: small;
  }
  #textFromCircularProgress {
    font-size: medium;
  }
}
