/* Estilos y comportamiento de los paneles */

/* Centraliza el main panel */


#mainPanelContainer
{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96%;
    margin-top: 1vw;
}

/* Main Panel */
#mainPanel
{
    background: #03759F;
    border: 0.5vw solid #5eccfc;
    border-radius: 0.8vw;
    width: 90%;
    height: 100%;
    margin: 3vh 0;

    /* Tenemos 3 columnas dentro del panel */
    display: grid;
    grid-template-columns: 25% 50% 25%; 

    position: relative;
    z-index: 1;
}

/* SUBPANELES */

/* Coin Panel, Username Panel y Game State Panel */
.subpanel
{
    background: #283a6c;
    border: 0.4vw solid #001f76;
    border-radius: 0.8vw;

    height: 90%;
    width: 80%;
    margin: 1vh 1vw;
    min-height: 25px;
    /*
    min-width: 120px;
    */

    display: inline-grid;

    position: relative;
    z-index: 2;
}

#logoPanel
{
    background: none;
    border: none;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: auto;
}

#coinsPanel
{
    width: 100%;
    padding: 0.2vh 0.2vw;
}


/* Top Elements Panel (valores sobreescritos) */
#topelementsPanel
{
    background: none;
    border: none;
    border-radius: 0;
    margin: 0;
    height: 100%;
    align-items: center;
    align-self: center;
    justify-content: center;
}

/* Game State Panel (valores sobreescritos) */
#gamestatePanel
{
    border-width: 0.3vw;
    border-color: #5eccfc;
    width: 95%;
    height: 50vh;
    display: grid;
    grid-template-columns: 1% 98% 1%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    pointer-events: auto;
}

/* Bottom Elements Panel (valores sobreescritos) */
#bottomelementsPanel
{
    background: none;
    border: none;
    border-radius: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}


/* CONTENEDORES DE LOS SUBPANELES */

/* Centraliza los subpaneles en su subcontenedor */
.subpanelContainer
{
    display: flex;
    justify-content: center;
}

/* Coins Panel position */
#coinsPanelContainer 
{
    /* Solo en columna 1 está */
    grid-column-start: 1;
    grid-column-end: 1;
    margin-left: 1vw;
    position: absolute;
    top: 10%;
}

/* Logo position */
#logoContainer
{
    /* En todas las columnas */
    grid-column: 1 / -1;
    margin-bottom: -1em;
}

/* Username Panel position */
#usernamePanelContainer
{
    /* Solo en columna 3 está */
    grid-column-start: 3;
    grid-column-end: 3;
    margin-right: 1vw;
    position: absolute;
    top: 10%;
}

/* Top Elements Panel position */
#topelementsPanelContainer
{
    grid-column: 1 / -1;
    margin: 0 1vw 0 1vw;
    text-align: center;
}

/* Game State Panel position */
#gamestatePanelContainer
{
    /* Todas las columnas */
    grid-column: 1 / -1;
    margin-left: 1vw;
    margin-right: 1vw;
    height: 90%;
}

/* Bottom Elements Panel position */
#bottomelementsPanelContainer
{
    /* Todas las columnas */
    grid-column: 1 / -1;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-bottom: 2vh;
    height: 40%;
    align-items: center;
    justify-content: center;
    margin-top: 0.6vh;
}

/* Accciones cuando la anchura es menor a la altura */
@media (max-aspect-ratio: 1/1) 
{
    #bottomelementsPanelContainer
    {
        margin-bottom: 0.5vh;
        height: 80%;
    }

    #bottomelementsPanel
    {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0;
    }

    #mainPanel
    {
        height: 95%;
        border-radius: 3.5vw;
    }

    #gamestatePanel
    {   
        margin: 0.1vh 0;
    }

    #coinsPanelContainer
    {
        top: 3%;
    }

    #coinsPanel
    {
        min-width: 90px;
        min-height: 32.5px;
    }

    #usernamePanelContainer
    {
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        top: 3%;
    }

    #usernamePanel
    {
        min-width: 70px;
        min-height: 35px;
        position: relative;
    }


    @media screen and (max-height: 800px)
    {
        #topelementsPanelContainer
        {
            height: 70%;
        }

        #bottomelementsPanelContainer
        {
            height: 95%;
            top: -20%;
            position: relative;
        }

        #gamestatePanelContainer
        {
            top: -5%;
            position: relative;
            height: 75%;
        }
    }

}

.infoIcon
{
    color: white;
}