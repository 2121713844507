#navButtonsRuletaStatusContainer
{
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1vw;
}

#navButtonsRuletaStatusContainer.navButtonsCentered
{
    position: relative;
    left: initial;
    margin: 0;
}

/* Accciones cuando la anchura es menor a la altura */
@media (max-aspect-ratio: 1/1)
{
    #navButtonsRuletaStatusContainer
    {
        bottom: 0;
        left: initial;
        margin: 0;
    }
}