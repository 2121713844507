@import url("../../../../styles/fonts.css");

/* width */
::-webkit-scrollbar 
{
    position: absolute;
    width: 1vw;
    border-radius: 0.3vw;
}
  
/* Track */
::-webkit-scrollbar-track 
{
    background: #f1f1f105;
}
  
/* Handle */
::-webkit-scrollbar-thumb 
{
    height: 1.5vw;
    background: rgb(73, 100, 255);
    border-radius: 0.3vw;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover
{
    background: rgb(130, 220, 255);
}
  
/* Contenedor de la flecha izquierda */
#previousGenContainer
{
    grid-column: 1;
    width: 1vw;
    height: 100%;
}

#previousGenContainer > .nextGenArrow
{
    left: 5%;
}

/* Contenedor de la flecha derecha */
#nextGenContainer
{
    grid-column: 3;
    width: 1vw;
    height: 100%;
}

#nextGenContainer > .nextGenArrow
{
    right: 5%;
}

.nextGenArrow 
{
    cursor: pointer;
    pointer-events: all;
    background-color: #fecb02;
    border: 0.3vw solid #ba9712;
    width: 4vw;
    height: 4vw;
    border-radius: 1vw;
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nextGenArrow svg
{
    color: #1a437b;
    width: 100%;
    height: 100%;
}

.nextGenArrow.reversed 
{
    transform: scaleX(-1);
}

.nextGenArrow.disabled
{
    pointer-events: none;
    opacity: 0.5;
}

#pokedexBigBox
{
    width: 100%;
    height: 96%;
    margin-top: 2%;
    margin-bottom: 2%;
    grid-column: 2;
    display: grid;
    justify-content: center;
    grid-template-columns: 10vw 10vw 10vw 10vw 10vw;
    grid-column-gap: 2vw;
    overflow-y: auto;
    pointer-events: all;
}

.generationTitle
{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.generationTitle > div > p
{
    margin-left: 0.5vw;
    display: flex;
    align-items: center;
}

.infoGenerationIcon
{
    color: white;
}

.loadingPokemon
{
    display: flex;
    justify-content: center; 
    align-items: center;
    height: 100%;
}

.rarity1 {
    border-color: rgb(190, 190, 190);
}

.rarity2 {
    border-color: rgb(145, 255, 184);
}

.rarity3 {
    border-color: rgb(142, 198, 255);
}

.rarity4 {
    border-color: rgb(216, 139, 255);
}

.rarity5 {
    border-color: rgb(255, 223, 39);
    animation-name: rareza5anim;
    animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
    animation-iteration-count: infinite;
}

.rarity6 {
    border-color: rgb(255, 100, 100);
    animation-name: rareza6anim;
    animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
    animation-iteration-count: infinite;
}

.yourRegisters {
    font-size: 150%;
}

@media screen and (max-width: 900px)
{
    #pokedexBigBox
    {
        grid-template-columns: 12.5vw 12.5vw 12.5vw 12.5vw;
    }
    .entryBox 
    {
        height: 12.5vw;
        width: 12.5vw;
    }
    .yourRegisters {
        font-size: 100%;
    }
}

@media screen and (max-width: 700px)
{
    #pokedexBigBox
    {
        grid-template-columns: 16.66vw 16.66vw 16.66vw;
    }
    .entryBox 
    {
        height: 16.66vw;
        width: 16.66vw;
    }
    .yourRegisters {
        font-size: 65%;
    }
}

#pokedexBigBox > div {
    margin-bottom: 2vw;
    filter: drop-shadow(0 0 2px rgb(0, 0, 0));
    transition: transform 0.3s ease, filter 0.3s ease; /* Agregamos una transición suave a transform y filter */
}

#pokedexBigBox > div:hover{
    transform: scale(1.05); /* Aumentamos el tamaño del elemento en un 5% al pasar el ratón sobre él */
    filter: drop-shadow(1px 1px 1px rgb(0, 0, 0)); /* Aplicamos una sombra más pronunciada */
}

@import url("./pokemonCard.css");

@import url("./types.css");

