#verPokemonAlmacenBigBox {
    width: 100%;
    pointer-events: all;
    overflow-y: none;
    grid-column: 2;
}
.MuiTypography-root {
    pointer-events: all;
}

.ivPercentage {
    position: absolute;
    width: 18%;
    align-items: center;
    justify-content: center;
    margin-top: 22vh;
    margin-right: 3vw;
    text-align: center;
    font-size: calc(1vh + 1.2vw);
}

.ivPercentage.fullivs {
    color: yellow;
}

.backButton {
    position: absolute;
    z-index: 5;
    top: 0;
    margin-top: 3vh;
    left: 0;
    width: 5%;
    height: 5%;
}

.backButton > a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.backArrow > svg
{
    text-align: center;
    transform: scaleX(-1);
    color: white;
    font-size: 1vw;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 800px) {
    #verPokemonAlmacenBigBox 
    {
        display: grid;
        grid-template-columns: 33% 33% 33%;
    }
}

#infoAdicional 
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5vh;
}

#infoAdicional > p {
    margin: 0;
}

.statsDiv {
    grid-column: 3;
    position: relative;
}


#statsFullStructure 
{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stats
{       
    z-index: 4;
}

.botonLiberar {
    background-color: #fb6c6c;
    border: 0.3vw solid #9f4949;
    border-radius: 0.7vw;
    width: 4vw;
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1vw;
    margin-left: 1vw;
    pointer-events: auto;
    min-width: 10px;
    min-height: 10px;
}

.botonLiberar > svg {
    color: #ffffff;
    width: 75%;
    height: 75%;
    pointer-events: none;
}

.statsTemplate {
    position: absolute;
    z-index: 3;
}

.elemTexts {
    position: absolute;
    z-index: 7;
}

p {
    color: white;
    text-align: center;
    font-size: 1.7vw;
}




.entrenadorOriginal {
    margin-bottom: -2vw;
}


#infoGeneral {
    position: relative;
    width: 100%;
    height: 80%;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2vh;
}

#infoGeneral div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inlineContainer svg {
    margin-left: 0.25vw;
    margin-right: 0.25vw;
    width: 24px;
    height: 24px;
}

#infoGeneral p {
    font-size: 2vw;
    margin: 0;
    margin-top: 3.5vh;
}

.TypographyText p {
    color: black;
    font-size: 100%;
}

.Typography-hoverContent > .shinyIcon {
    color: rgb(255, 230, 0);
    filter: drop-shadow(0 0 10px rgb(255, 230, 0));
}

.Typography-hoverContent > .megaIcon {
    color: rgb(255, 111, 159);
    fill: rgb(255, 111, 159);
    
    filter: drop-shadow(0 0 10px rgb(255, 111, 159));
}

.Typography-hoverContent > .megaIcon > g{
    width: 100%;
    height: 100%;
}

.Typography-hoverContent > .rareIcon {
    color: rgb(170, 164, 255);
    filter: drop-shadow(0 0 10px rgb(170, 164, 255));
}

.Typography-hoverContent > .eventIcon {
    color: rgb(255, 101, 101);
    filter: drop-shadow(0 0 10px rgb(255, 109, 109));
}



.inlineContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    
}

#infoGeneral > img {
    width: 13vw;
    height: auto;
    position: relative;
    margin-bottom: 2%;
    
    filter: drop-shadow(0 0 10px rgb(0, 0, 0));
}



#infoGeneral > img.shinyResplandor {
    animation-name: shinyResplandor;
    animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
    animation-iteration-count: infinite;
    ;
}

@keyframes shinyResplandor {
    0% {
        filter: drop-shadow(0 0 10px rgb(0, 0, 0));
    }
    50% {
       filter: drop-shadow(0 0 15px rgb(205, 183, 58)); /* Color más claro para mayor contraste */
    }
    100% {
        filter: drop-shadow(0 0 10px rgb(0, 0, 0));
    }
}

.nombreEO {
    color: rgb(182, 220, 254);
    font-size: 2vw;
}

.ValorRareza {
    color: rgb(182, 220, 254);
    margin-top: 0;
    font-size: 3vw;
}

.rareza > div > p > svg{
    width: calc(1vw + 1vh);
    height:  calc(1vw + 1vh);
    color: white;
}

.rareza {
    margin-right: 0.5vw;
    font-size: calc(10px + 1vw);
}

.pts {
    font-size: 1vw;
}

#statsDiv
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#tiposPokemon 
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    bottom: 0;
}

#tiposPokemon > div 
{
    width: 6vw;
    height: auto;
    padding: 0.5vh 1vw;
    border-radius: 2vw;
    font-size: calc(1px + 1vw);
}

.rareza1 {
    color: rgb(190, 190, 190);
}

.rareza2 {
    color: rgb(145, 255, 184);
}

.rareza3 {
    color: rgb(142, 198, 255);
}

.rareza4 {
    color: rgb(216, 139, 255);
}

.rareza5 {
    color: rgb(255, 223, 39);
    animation-name: rareza5anim;
    animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
    animation-iteration-count: infinite;
}

.rareza6 {
    color: rgb(255, 100, 100);
    animation-name: rareza6anim;
    animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
    animation-iteration-count: infinite;
}

.megaEffect {
    text-shadow: 0 0 10px rgb(255, 111, 159), 0 0 1px rgb(255, 111, 159);
    animation-name: shineRare;
    animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
}

.gmaxEffect {
    text-shadow: 0 0 10px rgb(255, 111, 159), 0 0 1px rgb(255, 111, 159);
    animation-name: shineRare;
    animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
}

.shinyEffect {
    text-shadow: 0 0 10px rgb(251, 255, 0), 0 0 1px rgb(251, 255, 0);
    animation-name: shineMega;
    animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
}

.rareEffect {
    
    text-shadow: 0 0 10px rgb(170, 164, 255), 0 0 1px rgb(170, 164, 255);
    animation-name: shineShiny;
    animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
}

.TypographyText {
    pointer-events: none;
}

.nombreEO.event {
    color: rgb(255, 173, 173);
}

@keyframes rareza5anim {
    0% {
        color: rgb(255, 223, 39);
    }
    50% {
        color: rgb(255, 238, 141); /* Color más claro para mayor contraste */
    }
    100% {
        color: rgb(255, 223, 39);
    }
}


@keyframes rareza6anim {
    0% {
        color: rgb(255, 100, 100);
    }
    50% {
        color: rgb(255, 202, 202); /* Color más claro para mayor contraste */
    }
    100% {
        color: rgb(255, 100, 100);
    }
}

#statsFullStructure 
{
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 800px)
{
    #verPokemonAlmacenBigBox 
    {
        
        display:grid;
        justify-content: center;
        flex-direction: column;
        overflow-y: scroll;
        height:100%;
        width: 100%;
        gap: 2vh;
    }

    #verPokemonAlmacenBigBox > div 
    {
        height: 100%;
        width: 100%;
    }

    #infoGeneral 
    {
        margin-top: 1vh;
        gap: 0.5vh;
    }

    #infoGeneral p
    {
        font-size: 100%;
    }



    #infoGeneral > img 
    {
        width: 50%;
        margin-bottom: 10%;
        
    }

    #statsDiv > p 
    {
        margin: 0;
        font-size: 100%;
    }

    #infoAdicional > p {
        font-size: 100%;
    }

    .backButton {
        position: absolute;
        z-index: 5;
        top: 0;
        margin-top: 1vh;
        left: 0;
        width: 15%;
        height: 15%;
    }
    
    #infoAdicional > div.inlineContainer > p {
        font-size: 100%;
    }
    
    #infoGeneral > p 
    {
        margin-top: 1.5vh;
        font-size: 100%;
    }

    #statsDiv > div.inlineContainer > p {
        font-size: 100%;
    }
    

    .TypographyText p {
        color: black;
        font-size: 60%;
    }
    
}

@import url("./types.css");