.rankBox 
{
    width: 60vw;
    height: 4.5vw;
    background-color: white;
    border-radius: 3vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 1.5vh;
}

.rankBox.yourself {
    background-color: rgb(246, 246, 198);
}

.rankContent
{
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 0 2vw;
}

.rankTitle
{
    height: 100%;
    display: flex;
    align-items: center;
}

.rankTitle > p
{
    color: black;
    font-size: 1.5vw;
}

.firstPosition
{
    color: gold;
    /*text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;*/
    -webkit-text-stroke: 2px;
    -webkit-text-stroke-color: black;
}
.secondPosition
{
    color: silver;
    -webkit-text-stroke: 2px;
    -webkit-text-stroke-color: black;
}

.thirdPosition
{
    color: #854300;
    -webkit-text-stroke: 2px;
    -webkit-text-stroke-color: black;
}

.rankData
{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.rankData > p
{
    color: black;
    font-size: 1vw;
}

.rankData > .pokemonImg
{
    width: 3.5vw;
    height: 3.5vw;
    position: relative;
    right: 0;
    top: 0;
    margin-left: 0.75vw;
}

/* Media queries for responsiveness */
@media (max-width: 1100px) {
    .rankBox {
        width: 70vw;
        height: 7vw;
        border-radius: 6vw;
    }

    .rankTitle > p {
        font-size: 2.2vw;
    }

    .rankData > p {
        font-size: 1.7vw;
    }

    .rankData > .pokemonImg {
        width: 5vw;
        height: 5vw;
    }
}
@media (max-width: 700px) {
    .rankBox {
        width: 80vw;
        height: 10vw;
        border-radius: 6vw;
    }

    .rankTitle > p {
        font-size: 2.5vw;
    }

    .rankData > p {
        font-size: 2vw;
    }

    .rankData > .pokemonImg {
        width: 7vw;
        height: 7vw;
    }

    .firstPosition, .secondPosition, .thirdPosition {
        -webkit-text-stroke: 0.5px;
        -webkit-text-stroke-color: black;
    }
}