@import url('../../../styles/fonts.css');

/* Título del actual game status */
#topElementTitle 
{
    font-family: vanilla-regular;
    color: white;
    margin: 0;
    font-size: calc(10px + 2.3vw);
    font-weight: 300;
}