@import url('../../../styles/fonts.css');

#username 
{
    display: grid;
    grid-template-columns: 40% 60%; 
    grid-row-gap: 0.8em;
}

#icon 
{
    top: 50%;
    left: 3%;
    transform: translate(0, -50%);
    position: relative;
    width: 64%;
    height: auto;
    pointer-events: none;
    color: white;
}

#username p
{
    margin: 0.3em 1em 0.3em 0;
    justify-self: center;
    align-self: center;
    color: white;
    font-family: vanilla-regular;
}

#username a
{
    color: white;
}