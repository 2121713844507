@import url("../../../../styles/fonts.css");

#almacenBigBox {
    width: 100%;
    height: 96%;
    margin-top: 2%;
    margin-bottom: 2%;
    grid-column: 2;
    display: grid;
    grid-template-rows: 25% 75%;
    
    overflow-y:auto;
    pointer-events: all;
    
}

.filterAlmacen {
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 100%;
}

a {
    pointer-events: all;
}

#pokemon-cards-container 
{
    width: 100%;
    height: 96%;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(5, auto);
    grid-column-gap: 3%;
    grid-row-gap: 8%;
}

#filtros {
    text-align: center;
    align-content: center;
    width: 100%;
    height: 80%;
    display: flex;
    font-family: vanilla-regular;
    color: white;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.filterAlmacenIcon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2vw;
}

#filtros svg {
    height: 5vh;
    width: auto;
}

.inputElem {
    max-height: 80%;
    margin-right: 1em;
    font-family: vanilla-regular;
    border-radius: 1vw;
    border: 0.2vw solid gray;
    color: gray;
    text-align: center;
    pointer-events: all;
    width: 15vw;
    box-sizing: border-box;
    height: 6vh;
    outline: none;
}

option 
{
    font-family: vanilla-regular; /* No parece afectar (?)*/
}

.inputElemSmall {
    max-height: 80%;
    margin-right: 1em;
    font-family: vanilla-regular;
    border-radius: 1vw;
    border: 0.2vw solid gray;
    color: gray;
    text-align: center;
    width: 10vw;
    height: 6vh;
    pointer-events: all;
}

#checkBoxContainer {
    max-height: 80%;
    
}

#checkBoxContainer > div {
    display: flex;
}

#checkBoxContainer > div > input {
    color: white;
    border: 0.2vw solid gray;
    pointer-events: all;
}

.loadingPokemon
{
    display: flex;
    justify-content: center; 
    align-items: center;
    height: 100%;
}

.borradoMultipleContainer
{
    position: absolute;
    bottom: 1vh;
    z-index: 4;
    width: 5%;
    right: 2.3vw;
    display: flex;
    flex-direction: column;
    min-width: 40px;
}

@media screen and (max-width: 670px)
{
    .borradoMultipleContainer
    {
        right: 2.5vw;
    }
}

.borradoMultipleContainer button
{
    font-family: vanilla-regular;
    color: white;
    background-color: #8A0000;
    border: 0.3vw solid #9f4949;
    border-radius: 0.8vw;
    height: 100%;
    width: 100%;
    margin-top: 0.7vh;
    pointer-events: all;
    font-size: calc(2px + 0.8vw);
    min-width: 0;
}

.borradoMultipleContainer button:hover 
{
    background-color: #7d3939;
}

#noPokemonMessage {
    position: absolute;
    width: 100%;
}

.borradoMultipleContainer button svg 
{
    width: 80%;
    height: 80%;
}

.borradoMultipleContainer #borradoMultipleConfirm
{
    background-color: #006400;
    border: 0.3vw solid #89ff8e;
}

.borradoMultipleContainer #borradoMultipleConfirm:hover 
{
    background-color: #006400;
}


.borradoMultipleConfirmDisabled
{
    opacity: 0.5;
}

.borradoMultipleConfirmDisabled:hover
{
    background-color: #006400 !important;
}

.borradoMultipleContainer div 
{
    width: 100%;
    height: 10%;
    margin-bottom: 1vh;
}

.borradoMultipleContainer div > p 
{
    position: relative;
    align-self: center;
}

.warningMessage {
    color: rgb(162, 0, 0);
}

.pokemonNameLiberar {
    color: black;
    margin: 0;
}

.rareSpeciesFound {
    color: rgb(170, 164, 255);
}

.megaFound {
    color: rgb(255, 111, 159);
}

.gmaxFound {
    color: rgb(255, 111, 159);
}

.shinyFound {
    color: rgb(225, 227, 104);
}

.modal-open {
    overflow: hidden;
    pointer-events: none;
}

.modal-open * {
    pointer-events: none;
}

.modal-open .MuiModal-root {
    pointer-events: all;
}

.filterAlmacen > select,
.filterAlmacen > input
{
    color: #424242;
}

.skipAllList
{
    width: 10vw;
    height: 5vh;
    position: absolute;
    top: 1%;
    left: 0.5%;
    background: #283a6c;
    border: 0.2vw solid #5eccfc;
    border-radius: 1vw;
    font-size: calc(3px + 0.7vw);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    color: white;
}

.skipAllList:focus
{
    opacity: 1;
}

@media screen and (max-width: 900px)
{
    #pokemon-cards-container
    {
        grid-template-columns: repeat(4, auto);
        grid-column-gap: 2.5%;
        grid-row-gap: 4%;
    }

    .entryBox 
    {
        height: 12.5vw;
        width: 12.5vw;
    }
}

@media screen and (max-width: 700px)
{
    
    #pokemon-cards-container
    {
        margin-top: 2vw;
        grid-template-columns: repeat(3, auto);
        grid-column-gap: 2.5%;
        grid-row-gap: 1%;
    }
    
    .entryBox 
    {
        height: 16.66vw;
        width: 16.66vw;
    }

    #filtros
    {
        display:grid;
        width: 90%;
        
        margin-left: 5%;
        margin-right: 5%;
    }

    #filtros > div > .inputElem{
        width: 100%;
    }

    #filtros > div > input.inputElem{
        width: 98%;
    }

    #filtros > div > .inputElemSmall {
        width: 100%;
    }

    #almacenBigBox {
        grid-template-rows: 10% 90%;
    }

    #filtros { 
        height: 95%;
    }

    #filtros > .filterAlmacen
    {
        display: none;
    }

    .filterAlmacenIcon
    {
        pointer-events: all;
        cursor: pointer;
    }

    .filterAlmacen.showedFilters
    {
        display: flex !important;
    }

    #almacenBigBox.showedFilters
    {
        grid-template-rows: 90% 90%;
    }
}

@import url("./pokemonCard.css");

@import url("./types.css");