@import url('../../../../styles/fonts.css');

#liberarButtonContainer
{
    right: 0;
    position: absolute;
    margin-right: 2vw;
}

#bottomelementsPanel > div > button 
{
    right: 0;
    position: relative;
}

.liberarButton {
    background-color: #fb6c6c;
    border: 0.3vw solid #9f4949;
    border-radius: 0.7vw;
    width: 4vw;
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    min-width: 30px;
    min-height: 30px;
    right: 0;
    transition: transform 0.3s ease;
}

.liberarButton > svg {
    color: #ffffff;
    width: 75%;
    height: 75%;
    pointer-events: none;
}

#modal-modal-title {
    font-size: calc(0.8vh + 1.2vw);
    text-align: center;
    font-family: vanilla-regular;
    color: #1a437b;
}

#modal-modal-description {
    color: black;
    margin-bottom: 2vh;
    margin-top: 2vh;
    text-align: justify;
    font-size: calc(0.6vh + 1.3vw);
    font-family: 'Roboto', sans-serif;
}

.MuiBox-root .entryBox {
    width: 10vw;
    justify-content: center;
}

.containerModal > a {
    pointer-events: none;
}

.containerModal > .entryBox {
    margin-bottom: 2vw;
}

.containerModal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.coin {
    width: 2vw;
    height: 2vw;
    margin-right: 0.4vw;
}

.moneyCount {
    font-size: calc(7px + 1vw);
    font-family: vanilla-regular;
    color: #82720C;
    display: flex;
    margin-bottom: 2vw;
    align-items: center;
}

.moneyCount > .coin
{
    min-width: 15px;
    min-height: 15px;
}

.moneyCount > p 
{ 
    color: #82720C;
}

@media screen and (max-width: 900px)
{
    .MuiBox-root .entryBox
    {
        width: 12.5vw;
        height: 12.5vw;
    }
}

@media screen and (max-width: 700px)
{
    .MuiBox-root .entryBox 
    {
        width: 16.66vw;
        height: 16.66vw;
    }
}

.liberarButtonTitle 
{
    color: white;
    font-family: vanilla-regular;
    position: absolute;
    font-size: 0.2vw;
    opacity: 0;
    text-transform: none;
    transition: transform 0.3s ease, opacity 0.3s ease, font-size 0.3s ease
}

.liberarButton:hover
{
    transform: translateY(-0.3vw);
}

.liberarButton:hover .liberarButtonTitle
{
    transform: translateY(3vw);
    opacity: 100%;
    font-size: 0.9vw;
}

.modal-open {
    overflow: hidden;
    pointer-events: none;
}

.modal-open * {
    pointer-events: none;
}

.modal-open .MuiModal-root {
    pointer-events: all;
}

/* Accciones cuando la anchura es menor a la altura */
@media (max-aspect-ratio: 1/1)
{
    #liberarButtonContainer
    {
        margin: 1vh 0 2vh 0;
        right: initial;
        top: -80%;
    }

    .liberarButton 
    {
        min-width: 40px;
        min-height: 40px;
        margin: 0;
    }

    .liberarButtonTitle 
    {
        font-size: calc(0.4vw + 6px);
        opacity: 1;
        top: 90%;
    }

    .liberarButton:hover
    {
        transform: translateY(0);
    }
    
    .liberarButton:hover .liberarButtonTitle
    {
        font-size: calc(0.4vw + 6px);
        opacity: 1;
        transform: translateY(-25%);
    }
    
}