.normal {
  background-color: #676869;
}

.fire {
  background-color: rgb(155, 60, 50);
}

.water
{
    background-color: #3068d0;
}

.grass {
  background-color: #1C8730;
}

.bug
{
    background-color: #006728;
}

.poison {
  background-color: rgb(111, 62, 187);
}

.electric {
  background-color: #846e00;
}

.ground {
  background-color: #6A5935;
}

.rock {
  background-color: rgb(115, 97, 64);
}

.fairy {
  background-color: #876a96;
}

.fighting {
  background-color: rgb(155, 73, 50);
}

.psychic {
  background-color: #b12ea6;
}

.ghost {
  background-color: rgb(65, 26, 98);
}

.ice 
{
    background-color: #437c8e;
}

.dragon {
  background-color: rgb(17, 22, 91);
}

.steel {
  background-color: #646285;
}

.dark {
  background-color: rgb(44, 48, 60);
}

.flying 
{
    background-color: #61769d;
}

.entryBox {
  border: 0.3vw solid rgba(255, 255, 255, 0.2);
}

.entryBox {
  border-width: 0.3vw;
  border-style: solid;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.entryBox.rare {
  border-color: rgb(170, 164, 255);
  animation-name: shineRare;
  animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
}

.entryBox.mega {
  border-color: rgb(255, 111, 159);
  animation-name: shineMega;
  animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
}

.entryBox.gmax {
  box-shadow: 0 0px 1vw 0 rgb(255, 111, 159), 
  0px -30px 40px rgba(255, 111, 159, 0.5) inset,
  0px 30px 20px rgba(0, 0, 0, 0.3) inset;
  border-color: rgb(255, 111, 159);
  animation-name: shineMega;
  animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
}

.entryBox.shiny {
  border-color: rgb(251, 255, 0);
  animation-name: shineShiny;
  animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
}

.entryBox.shiny.mega {
  animation-name: shineShinyMega;
  animation-duration: 10s; /* Aumenta la duración para hacerla más lenta */
}

.entryBox.shiny.gmax {
  animation-name: shineShinyMega;
  animation-duration: 10s; /* Aumenta la duración para hacerla más lenta */
}

.entryBox.shiny.rare {
  animation-name: shineShinyRare;
  animation-duration: 10s; /* Aumenta la duración para hacerla más lenta */
}

.entryBox.event {
  animation-name: shineEvent;
  animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
}

.entryBox.shiny.mega.event {
  animation-name: shineEvent;
  animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
}

.entryBox.shiny.gmax.event {
  animation-name: shineEvent;
  animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
}

.notLiberado {
  opacity: 0.5;
  pointer-events: all;
}

.entryBox.liberado {
  opacity: 1;
  pointer-events: all;
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.entryBox.liberado.shiny {
  opacity: 1;
  pointer-events: all;
  animation: shake 0.5s infinite, shineShiny 10s infinite;
  animation-iteration-count: infinite;
}

.entryBox.liberado.rare {
  opacity: 1;
  pointer-events: all;
  animation: shake 0.5s infinite, shineRare 10s infinite;
  animation-iteration-count: infinite;
}

.entryBox.liberado.mega {
  opacity: 1;
  pointer-events: all;
  animation: shake 0.5s infinite, shineMega 10s infinite;
}

.entryBox.liberado.gmax {
  opacity: 1;
  pointer-events: all;
  animation: shake 0.5s infinite, shineMega 10s infinite;
}

.entryBox.liberado.mega.shiny {
  opacity: 1;
  pointer-events: all;
  animation: shake 0.5s infinite, shineShinyMega 10s infinite;
}

.entryBox.liberado.gmax.shiny {
  opacity: 1;
  pointer-events: all;
  animation: shake 0.5s infinite, shineShinyMega 10s infinite;
}

.entryBox.liberado.rare.shiny {
  opacity: 1;
  pointer-events: all;
  animation: shake 0.5s infinite, shineRareShiny 10s infinite;
}

.entryBox.liberado.event {
  opacity: 1;
  pointer-events: all;
  animation: shake 0.5s infinite, shineEvent 5s infinite;
}

.entryBox.seleccionado {
  opacity: 1;
  pointer-events: all;
  animation: shake 0.5s infinite, shineEvent 5s infinite;
  background-color: #00df09;
}

#tiposPokemon > .pokemonType 
{
  box-shadow: 0 0 5px rgb(40, 40, 40);
  min-width: 40px;
  font-size: calc(6px + 1vw);
}

@keyframes shake {
  0%,
  100% {
    transform: rotate(1deg);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: rotate(-1deg);
  }
  20%,
  40%,
  60%,
  80% {
    transform: rotate(1deg);
  }
}

@keyframes shineRare {
  0% {
    border-color: rgb(170, 164, 255);
  }
  50% {
    border-color: rgb(241, 240, 255); /* Color más claro para mayor contraste */
  }
  100% {
    border-color: rgb(170, 164, 255);
  }
}

@keyframes shineMega {
  0% {
    border-color: rgb(255, 111, 159);
  }
  50% {
    border-color: rgb(255, 230, 242); /* Color más claro para mayor contraste */
  }
  100% {
    border-color: rgb(255, 111, 159);
  }
}

@keyframes shineShiny {
  0% {
    border-color: rgb(251, 255, 0);
  }
  50% {
    border-color: rgb(255, 255, 237); /* Color más claro para mayor contraste */
  }
  100% {
    border-color: rgb(251, 255, 0);
  }
}

@keyframes shineEvent {
  0% {
    border-color: rgb(134, 0, 31);
  }
  50% {
    border-color: rgb(255, 176, 176); /* Color más claro para mayor contraste */
  }
  100% {
    border-color: rgb(134, 0, 31);
  }
}

@keyframes shineShinyRare {
  0% {
    border-color: rgb(251, 255, 0);
  }
  25% {
    border-color: rgb(255, 255, 237); /* Color más claro para mayor contraste */
  }
  50% {
    border-color: rgb(170, 164, 255); /* Color más claro para mayor contraste */
  }
  75% {
    border-color: rgb(241, 240, 255); /* Color más claro para mayor contraste */
  }
  100% {
    border-color: rgb(251, 255, 0);
  }
}

@keyframes shineShinyMega {
  0% {
    border-color: rgb(251, 255, 0);
  }
  25% {
    border-color: rgb(255, 255, 237); /* Color más claro para mayor contraste */
  }
  50% {
    border-color: rgb(255, 111, 159); /* Color más claro para mayor contraste */
  }
  75% {
    border-color: rgb(255, 230, 242); /* Color más claro para mayor contraste */
  }
  100% {
    border-color: rgb(251, 255, 0);
  }
}
