@import url('../../../../styles/fonts.css');

/* Contenedor del botón */
.navButton
{
    background-color: #fecb02;
    border: 0.3vw solid #ba9712;
    border-radius: 0.7vw;
    width: 4vw;
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1vw;
    margin-left: 1vw;
    pointer-events: auto;
    transition: transform 0.3s ease
}

/* Icono del botón */
.navButton > svg 
{
    color: #1a437b;
    fill: #1a437b;
    width: 80%;
    height: 80%;
    pointer-events: none;
}

.selected
{
    background-color: #1a437b;
}

.selected > svg 
{
    color: #fecb02;
    fill: #fecb02;
}

.navButtonTitle 
{
    color: white;
    font-family: vanilla-regular;
    position: absolute;
    font-size: 0.2vw;
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease, font-size 0.3s ease;
}

.navButton:hover
{
    transform: translateY(-0.3vw);
}

.navButton:hover .navButtonTitle
{
    transform: translateY(3vw);
    opacity: 100%;
    font-size: 0.9vw;
}

/* Accciones cuando la anchura es menor a la altura */
@media (max-aspect-ratio: 1/1)
{
    .navButton
    {
        min-width: 40px;
        min-height: 40px;
        margin: 0 1.5vw;
    }

    .navButton
    {
        transition: none;
    }

    .navButtonTitle
    {
        transition: transform 0.3s ease;
    }

    .navButton:hover
    {
        transform: translateY(0);
    }

    .navButton:hover .navButtonTitle
    {
        font-size: calc(0.4vw + 6px);
        opacity: 1;
        transform: translateY(-25%);
    }

    .navButtonTitle 
    {
        font-size: calc(0.4vw + 6px);
        opacity: 1;
        top: 90%;
    }

}