.entryBox {
    width: 100%;
    height: 10vw;
    width: 10vw;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 2vw;
    position: relative;
}
  


#pokemon-cards-container > a 
{
    filter: drop-shadow(0 0 2px rgb(0, 0, 0));
    transition: transform 0.3s ease, filter 0.3s ease; /* Agregamos una transición suave a transform y filter */
    height: fit-content;
}

#pokemon-cards-container > a:hover {
    transform: scale(1.05); /* Aumentamos el tamaño del elemento en un 5% al pasar el ratón sobre él */
    filter: drop-shadow(1px 1px 1px rgb(0, 0, 0)); /* Aplicamos una sombra más pronunciada */
}

.pokemonImg
{
    width: 60%;
    height: auto;
    position: absolute;
    right: 2%;
    top: 10%;
    
    filter: drop-shadow(0 0 3px rgb(0, 0, 0));
}

.pokemonImg.shinyResplandor {
    
    animation-name: shinyResplandor1;
    animation-duration: 5s; /* Aumenta la duración para hacerla más lenta */
    animation-iteration-count: infinite;
}

@keyframes shinyResplandor1 {
    0% {
        filter: drop-shadow(0 0 3px rgb(0, 0, 0));
    }
    50% {
       filter: drop-shadow(0 0 6px rgb(205, 183, 58)); /* Color más claro para mayor contraste */
    }
    100% {
        filter: drop-shadow(0 0 3px rgb(0, 0, 0));
    }
}

@media screen and (max-width: 900px)
{
    #pokedexBigBox
    {
        grid-template-columns: 12.5vw 12.5vw 12.5vw 12.5vw;
    }
    .entryBox 
    {
        height: 12.5vw;
        width: 12.5vw;
    }
}

@media screen and (max-width: 700px)
{
    #pokedexBigBox
    {
        grid-template-columns: 16.66vw 16.66vw 16.66vw;
    }
    .entryBox 
    {
        height: 16.66vw;
        width: 16.66vw;
    }
}

#pokedexBigBox > p
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: none;
    color: white;
    grid-column: 1 / -1;
}

/* Número del Pokémon */
.dexNumber 
{
    font-family: vanilla-regular;
    font-size: calc(3px + 1.1vw);
    margin-top: 0.5vw;
    margin-left: 1vw;
    color: white;
    position: absolute;
    z-index: 4;
}

.pokemonName 
{
    font-family: vanilla-regular;
    font-size: calc(2px + 1.1vw);
    width: 100%;
    text-align: center;
    color: white;
    margin-top: 5.5vw;
    bottom: -0.5vh;
    position: absolute;
}

.types
{
    position:absolute;
    max-width: 4vw;
    top: 30%;
    left: 0;
}

.pokemonType 
{
    font-family: vanilla-regular;
    position: relative;
    font-size: calc(2.5px + 0.6vw);
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: calc(5px + 0.3vw);
    margin-bottom: 0.3vw;
    border-radius: 0.5vw;
    color: white;
    width: 4vw;
    background-color: rgba(0, 0, 0, 0.1);
}

/* Letras ??? de pokémon no descubierto */
.unknownMessage 
{
    font-family: vanilla-regular;
    font-size: 2vw;
    color: white;
    text-align: center;
    position: relative;    
    margin: 0;
}

.unknownMessageContainer
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.notLiberado
{
    opacity: 0.5;
    pointer-events: all;
}

.entryBox.liberado
{
    opacity: 1;
    pointer-events: all;
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0%, 100% { transform: rotate(0deg); }
    10%, 30%, 50%, 70%, 90% { transform: rotate(-2deg); }
    20%, 40%, 60%, 80% { transform: rotate(2deg); }
  }