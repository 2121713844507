.externalArrowContainer
{
    position: relative;
    height: 10%;
}

.boxes
{
    display: grid;
    grid-template-columns: 23% 23% 23%;
    grid-column-gap: 2%;
    grid-row-end: 5%;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 100%;
}



.ruletaBox
{
    background-color: white;
    border : 0.3vw solid black;
    height: 90%;
    border-radius: 1vw;
    justify-content: center;
    display:flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    pointer-events: none;
}

.ruletaBox.enabled:hover
{
    background-color: #fecb02;
    border-color: #ba9712;
}

.ruletaBox.enabled:focus {
    background-color: #fecb02;
    border-color: #ba9712;
}

.ruletaBox.enabled
{
    pointer-events: all;
}

canvas {
    position: absolute;
    width: 100%;
    height: 100%;
}

.ruletaBox > img {
    top: auto;
    right: auto;
    position: relative;
    width: 100%;
}

.pokeballRotar {
    animation: rotarAnimacion 4s linear infinite;
}

@keyframes rotarAnimacion {
    from {
        transform: rotate(0deg); /* Ángulo de inicio de rotación */
    }
    to {
        transform: rotate(360deg); /* Ángulo final de rotación (1 vuelta completa) */
    }
}

#reclamarButtonContainer .containerModal > .pokemonImg 
{
    position: relative;
    width: 40%;
    margin-bottom: 2vh;
    pointer-events: none;
    min-width: 60px;
}

button .cerrarButton {
    pointer-events: all;
}

.unregisterMessage {
    color: #AC0600;
    display:flex;
    justify-content: center;
    margin: 0;
    margin-bottom: 2vh;
    font-size: calc(8px + 1vw);
}

.registerMessage {
    color: rgb(81, 199, 54);
    display:flex;
    justify-content: center;
    margin: 0;
    margin-bottom: 2vh;
}

.RegistradoCheck {
    position: absolute;
    top: 0;
    right: 0;
    color: #006400;
    display: flex;
    justify-content: right;
    margin: 0;
    margin-bottom: 2vh;
    margin-right: 0.75vh;
    margin-top: 0.5vh;
    font-size: large;
}

.RarezaBox {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    
    z-index: 3;
}

.RarezaText {
    display: inline-block;
    /* Ajusta estos valores según sea necesario para la responsividad */
    font-size: 1.5vw; 
}

.containerModal svg {
    width: 24px;
    height: 24px;
}

.nombrePokemonReclamar {
    color: black;
    font-size: calc(10px + 1vw);
}

.modal-open {
    overflow: hidden;
    pointer-events: none;
}

.modal-open * {
    pointer-events: none;
}

.modal-open .MuiModal-root {
    pointer-events: all;
}

.modal-open .inlineContainer > div > p {
    pointer-events: none;
}

@media screen and (max-width: 900px) {
    .RarezaText {
        font-size: 2vw;
        
    }
    
    .containerModal svg {
        width: 3vw;
        height: 3vw;
    }
}

@media screen and (max-width: 700px) {
    .RarezaText {
        font-size: 2.5vw;
        
    }
}

#reclamarButtonContainer .pokeballRotar 
{
    width: 50%;
}