@import url('./fonts.css');

*
{
    user-select: none;
    pointer-events: none;
    font-family: vanilla-regular;
}

html 
{
    height: 100vh;
    width: 100vw;
    
}

body 
{
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: black;
    background-image: url('../images/bg.jpg'); 
    background-size: 100vw 100vh; /* Responsivo */
    background-repeat: no-repeat; 
    background-position: center;
    overflow: hidden; /* No scrolling */
}

#root 
{
    height: 100%;
}

::placeholder {
    color: #424242; 
    opacity: 1; 
}

/* Cambiar el color del placeholder en Internet Explorer 10-11 */
:-ms-input-placeholder {
    color: #424242; 
}

/* Cambiar el color del placeholder en Edge */
::-ms-input-placeholder {
    color: #424242; 
}

/* Cambiar el color del placeholder en Mozilla Firefox */
::-moz-placeholder {
    color: #424242; 
    opacity: 1;
}

/* Cambiar el color del placeholder en Google Chrome */
::-webkit-input-placeholder {
    color: #424242; 
}


@media (max-aspect-ratio: 1/1) 
{
    #mouse-over-popover
    {
        max-width: 120vw !important;
    }
}

